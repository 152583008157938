<template>
<div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
        <div class="d-flex p-0">
            <div class="card-title row w-100">
                <div class="d-flex p-0 align-items-center position-relative mb-4">
                    <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                </div>
                <div class="d-flex flex-column p-0 position-relative mb-4">
                    <span>Create Product Receiving</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body tab-content text-gray-700" id="tab_raw_product">
        <div class="fv-row mb-7">
            <label class="fs-6 fw-bold mb-2">Kode Purchase Order</label>
            <div class="row">
                <div class="col-auto">
                    <el-select
                        v-model="kode_po"
                        filterable
                        allow-create
                        default-first-option
                        :reserve-keyword="false"
                        placeholder="Masukkan Kode Purchase Order"
                    >
                        <el-option
                          v-for="(item, i) in listPurchaseOrderReady"
                          :key="i"
                          :label="item.kode_po + ' - ' + item.vendor"
                          :value="item.kode_po"
                        />
                    </el-select>
                </div>
                <div class="col-auto">
                    <button v-if="tableData.length == 0" class="btn btn-primary" @click="getData()">Add</button>
                    <el-popconfirm
                        v-else
                        confirm-button-text="OK"
                        cancel-button-text="No"
                        :icon="InfoFilled"
                        title="Apakah yakin ingin reset?"
                        @confirm="tableData.splice(0), kode_po = null"
                    >
                        <template #reference>
                            <button class="btn btn-danger ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                Reset
                            </button>
                        </template>
                    </el-popconfirm>
                </div>
            </div>
        </div>

        <div class="fv-row mb-10">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <div class="fv-row mb-7">
                  <label class="required fs-6 fw-bold mb-2 col-auto">Deskripsi</label>
                  <el-form-item
                    class="fv-row mb-7"
                    prop="description"
                    :rules="[
                      { required: true, message: 'Description is required' },
                    ]"
                  >
                    <el-input v-model="tempData.description" name="description" class="form-control p-0 border-0" placeholder="Deskripsi" size="large"></el-input>
                  </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="fv-row mb-7">
            <label class="required fs-6 fw-bold mb-2 col-auto">List product</label>
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th class="w-300px">Nama</th>
                            <th class="w-200px">Artikel</th>
                            <th class="w-300px">SKU</th>
                            <th class="w-300px">Kategori</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th class="text-end w-300px" title="Qty purchase order">Qty Request <i class="fas fa-exclamation-circle text-gray-400"></i></th>
                            <th class="text-end w-300px" title="Qty yang pernah diterima sebelumnya">Qty Saat ini <i class="fas fa-exclamation-circle text-gray-400"></i></th>
                            <th class="text-end w-300px" title="Qty yang masih perlu diterima">Qty Tersisa <i class="fas fa-exclamation-circle text-gray-400"></i></th>
                            <th class="text-end w-300px" title="Qty penerimaan">Qty Diterima <i class="fas fa-exclamation-circle text-gray-400"></i></th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ i+1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>
                            <td class="text-end">{{ item.qty_final }}</td>
                            <td class="text-end">{{ item.qty_acc }}</td>
                            <td class="text-end">{{ item.qty_final - item.qty_acc }}</td>
                            <td class="text-end">
                                <div class="row justify-content-end">
                                    <input type="number" class="form-control w-100px" placeholder="Qty" v-model="item.qty" :name="'qty'+item.index" />
                                    <div class="fv-plugins-message-container" v-if="!item.qty || item.qty <= 0">
                                        <div class="fv-help-block">
                                            <span class="text-danger" :name="'qty'+item.index">Wajib diisi</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td scope="col" class="text-end w-150px">
                                <div class="d-flex justify-content-end">
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Are you sure to delete this?"
                                        @confirm="onDelete(i)"
                                    >
                                        <template #reference>
                                            <button class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <div class="row justify-content-center">
            <button
                type="submit"
                @click="confirmSubmit(formRef)"
                class="btn btn-primary w-350px my-7 justify-content-end"
                :data-kt-indicator="loadingButton ? 'on' : 'off'"
            >
                <span class="indicator-label"> Submit </span>

                <span class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>

    </div>

    <el-dialog v-model="modal" :title="'Create Product Receiving'" :close-on-click-modal="true" width="50%" align-center>
        <div class="fs-6 mb-3">
            Apakah yakin ingin buat penerimaan barang {{kode_po}}? 
        </div>
        <template #footer>
            <div class="row justify-content-end gs-1">
                <div class="col-auto">
                    <button class="btn btn-danger btn-sm" @click="modal = false">Cancel</button>
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary btn-sm" type="button" @click="onSubmit()">Ok</button>
                </div>
            </div>
        </template>
    </el-dialog>
</div>
</template>

<style scoped lang="scss">
    $color_primary: #EE7305;
    $add_product_size: 180px;

    .product-distribusi {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(auto, $add_product_size));
        text-align: left;
        position: relative;
        height: 100%;
        overflow-y: scroll;
        padding: .5rem;
        gap: 1rem;
        grid-auto-rows: minmax(min-content, max-content);

        .list-product-distribusi {
            display: flex;
            flex-direction: column;

            img, span {
                margin-bottom: .5rem;
            }

            img {
                border: solid 1px #eee;
                width: 100%;
                height: $add_product_size;
            }

            span {
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .list-product-distribusi.add-product-distribusi {
            height: $add_product_size;
            align-items: center;
            border: dashed 3px $color_primary;
            cursor: pointer;

            i {
                color: $color_primary !important;
                font-size: 200%;
                line-height: $add_product_size;
                width: 100%;
                text-align: center;
            }
        }

        .list-product-distribusi.add-product-distribusi:hover {
            border-style: solid;
        }
    }
</style>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR, validFileSize, validFileType } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElLoading, Elpagination, FormInstance, FormRules} from 'element-plus'
//import { InfoFilled } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'


export default defineComponent({
    components: { 
        //Datatable,
        //Field, 
        //Form,    
        //ErrorMessage, 
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const img_url_products = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const loadingTable = ref(true);
        
        const loadingButton = ref(false);
        const disabledButton = ref(true)
        const submitButton = ref(null)

        const modal = ref(false)

        const formRef = ref(null)
        const kode_po = ref(null)

        const tableData = reactive([])

        const tempData = reactive({
            description: null,
        })

        const listPurchaseOrderReady = reactive([])

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true

            await getData(page)
            loadingTable.value = false
        }

        const confirmSubmit = async (formEl) => {
            if (!formEl) return

            await formEl.validate( async(valid, fields) => {

                try {
                    if (valid) {

                        if(tableData.some(o => !o.qty || o.qty <= 0)) {
                            throw new Error('Quantity wajib diisi')
                        }

                        modal.value = true

                    } else {
                        Swal.fire({
                            title: "Please check form before submit",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000
                        });
                        throw new Error('Please check form before submit.')
                    }

                    loadingButton.value = false
                
                } catch(e) {
                    Swal.fire({
                        title: e.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000
                    });

                }

                loadingButton.value = false
            })
        }

        const onSubmit = async() => {
            try {
                loadingButton.value = true
                modal.value = false

                await ApiService.post("inventory/create_receiving", { kode_po: kode_po.value, description: tempData.description, data: tableData })
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    tableData.splice(0)
                })
                .catch(({ response }) => {  
                    if(Object.entries(response.data.messages)[0][1]) {
                        throw new Error(Object.entries(response.data.messages)[0][1])
                    } else {
                        throw new Error(response.data.messages)
                    }
                });
            } catch(e) {

                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }

            loadingButton.value = false
        }

        const getData = async() => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                if(!kode_po.value) {
                    throw new Error('Harap masukkan kode purchase order')
                }

                await ApiService.get("supplier/purchase_order/"+kode_po.value)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    data.data.data.map(o => o.qty = 0)

                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {  
                    throw new Error(response)
                });

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const getReadyPurchaseOrder = async() => {
            try {
                await ApiService.get("supplier/purchase_order_ready")
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(listPurchaseOrderReady, data.data)
                })
                .catch(({ response }) => {  
                    throw new Error(response)
                });

            } catch (e) {
                console.error(e)
            }
        }

        const onDelete = async (index) => {
            try {
                tableData.splice(index, 1)

            }
            catch(e) {
                Swal.fire({
                    title: 'Terjadi error',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });

            }

            disabledButton.value = true
            loadingButton.value = false
        }

        onBeforeMount(() => {
            setCurrentPageTitle(route.meta.title);
            setCurrentPageBreadcrumbs('Inventory', [route.meta.title]);

            getReadyPurchaseOrder();
        })

        return {
            route, router,
            formatIDR,
            img_url_desain, img_url_products, 
            disabledButton, loadingButton, submitButton,
            pagination, handlePageChange, 
            formRef, modal,
            tableData, kode_po, tempData,  listPurchaseOrderReady,
            getData, confirmSubmit, onSubmit, onDelete,
        }
    }
})
</script>